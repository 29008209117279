import {Group, Stack, Title, useMantineTheme} from "@mantine/core";
import IconCard from "./components/IconCard";
import { IconCalendarEvent, IconStethoscope } from "@tabler/icons";

const Home = () => {

  const theme = useMantineTheme();

  return (
    <Stack align="center" justify="center" spacing="xl">
      <Title order={1} align="center" style={{ marginBottom: theme.spacing.sm }}>
        Welcome to Doctor Booking Page
      </Title>
      <Title order={3} align="center">
        What you want to do?
      </Title>

      <Group position="center">
        <IconCard
          icon={<IconStethoscope size={72} />}
          title={"Make a booking"}
          href={"/book"}
        />
        <IconCard
          icon={<IconCalendarEvent size={72} />}
          title={"View my booking"}
          href={"/bookings"}
        />
      </Group>
    </Stack>
  );
};

export default Home;
