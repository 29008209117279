import { Stepper } from "@mantine/core";
import {
  IconCircleCheck,
  IconClock,
  IconId,
  IconUserSearch, IconZoomCheck,
} from "@tabler/icons";

const CustomStepper = ({ active, setActive }) => {
  return (
      <Stepper
        active={active}
        onStepClick={setActive}
        breakpoint="sm"
        completedIcon={<IconCircleCheck />}
        px="lg"
        color="pink"
      >
        <Stepper.Step
          allowStepSelect={false}
          icon={<IconUserSearch size={18} />}
          label="Step 1"
          description="Select a doctor"
        />
        <Stepper.Step
          allowStepSelect={false}
          icon={<IconClock size={18} />}
          label="Step 2"
          description="Pick a timeslot"
        />
        <Stepper.Step
          allowStepSelect={false}
          icon={<IconId size={18} />}
          label="Step 3"
          description="Fill your Info"
        />
        <Stepper.Step
          allowStepSelect={false}
          icon={<IconZoomCheck size={18} />}
          label="Step 4"
          description="Review booking"
        />
      </Stepper>
  );
};

export default CustomStepper;
