import DoctorForm from "./DoctorForm";
import { useContext } from "react";
import BookViewModel from "../../viewModel";
import InfoForm from "./InfoForm";
import { Button, Group } from "@mantine/core";
import TimeslotForm from "./TimeslotForm";
import DoctorModal from "../DoctorModal";
import FormReview from "./FormReview";
import FormNavButtons from "./FormNavButtons";

const BookForm = () => {
  const {
    bookDoctor,
    bookForm,
    bookStep,
    submitBook,
    showDoctorDetail,
  } = useContext(BookViewModel.Context);

  return (
    <form onSubmit={bookForm.onSubmit(submitBook)}>
      {bookStep === 0 && <DoctorForm onBook={() => bookDoctor("test")} />}
      {bookStep === 1 && <TimeslotForm form={bookForm} />}
      {bookStep === 2 && <InfoForm form={bookForm} />}
      {bookStep === 3 && <FormReview />}
      <FormNavButtons />
      {showDoctorDetail && <DoctorModal />}
    </form>
  );
};

export default BookForm;
