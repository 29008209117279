import Home from "../views/Home";
import Book from "../views/Book";
import Bookings from "../views/Bookings";

import { IconHome, IconStethoscope, IconCalendarEvent } from '@tabler/icons';

export const LINKS = [{
  title: "Home",
  href: "/",
  icon: <IconHome />,
  render: <Home />,
},{
  title: "Book a doctor",
  href: "/book",
  icon: <IconStethoscope />,
  render: <Book />,
}, {
  title: "My bookings",
  href: "/bookings",
  icon: <IconCalendarEvent />,
  render: <Bookings />,
}]