function APIHelper(method, url, data) {
  const headers = {
    Accept: "application/json, text/plain, /",
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY,
  };

  let isFailed = false;

  const options = { method, headers };

  if (data) options.body = JSON.stringify(data);

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((res) => {
        if (res.status === 200 || res.status === 201 || res.status === 202) {
          return res.json();
        }
        isFailed = true;
        return res.json();
      })
      .then((response) => {
        if (isFailed) throw response;
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default APIHelper;
