import { Routes, Route } from "react-router-dom";
import { LINKS } from "../../constants/navbar";

const CustomRoute = () => {
  return (
    <Routes>
      {LINKS.map((link) => (
        <Route path={link.href} element={link.render} key={link.href} exact/>
      ))}
      <Route path="*" element={<p>Page Not Found</p>} />
    </Routes>
  );
};

export default CustomRoute;
