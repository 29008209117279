import React, { useState } from "react";
import { AppShell, useMantineTheme } from "@mantine/core";
import CustomHeader from "./Header";
import CustomNavbar from "./Navbar";

const Layout = ({ children }) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  return (
    <AppShell
      styles={{
        main: {
          background: theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed
      navbar={<CustomNavbar opened={opened} />}
      header={
        <CustomHeader setOpened={setOpened} opened={opened} theme={theme} />
      }
    >
      {children}
    </AppShell>
  );
};

export default Layout;
