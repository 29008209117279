import { TextInput, Box } from "@mantine/core";

const InfoForm = ({form}) => {
  return (
    <Box sx={{ maxWidth: 300 }} mx="auto">
        <TextInput
          required
          label="Full Name"
          {...form.getInputProps("name")}
        />
    </Box>
  );
};

export default InfoForm;
