import './App.css';
import Layout from "./components/layout/Layout";
import CustomRoute from "./components/route/Route";
import {useEffect} from "react";

function App() {

  useEffect(() => {
    document.title = "Doctor Booking Page"
  }, [])

  return (
    <Layout>
      <CustomRoute />
    </Layout>
  );
}

export default App;
