import React, { createContext } from "react";

const BaseViewModel = function (model) {
  const Context = createContext(null);
  const withProvider = (ViewComponent) => (props) => {
    const value = model();
    return (
      <Context.Provider value={value}>
        <ViewComponent {...props} />
      </Context.Provider>
    );
  };
  return {
    Context,
    withProvider,
  };
};

export default BaseViewModel;
