import APIHelper from "./APIHelper";

export const APIRequestGenerator = (
  rawURL,
  params = null,
) => {
  let url = new URL(rawURL);
  if (params && typeof params === "object") {
    Object.keys(params).forEach((key) => {
      url.searchParams.set(key, params[key]);
    });
  }
  url = url.toString();
  return { url };
};

const GeneralAPICall = (resource) => {
  function create(data) {
    return new Promise(async (resolve, reject) => {
      const { url } = APIRequestGenerator(
        `${process.env.REACT_APP_HOST}/${resource}`,
        null,
      );
      try {
        const response = await APIHelper(
          "POST",
          url.toString(),
          data,
        );
        return resolve(response);
      } catch (error) {
        return reject(error);
      }
    });
  }

  function getList(params) {
    return new Promise(async (resolve, reject) => {
      const { url } = APIRequestGenerator(
        `${process.env.REACT_APP_HOST}/${resource}`,
        params,
      );
      try {
        const response = await APIHelper(
          "GET",
          url.toString(),
          null,
        );
        return resolve(response);
      } catch (error) {
        return reject(error);
      }
    });
  }

  function getOne(id, params) {
    return new Promise(async (resolve, reject) => {
      const { url } = APIRequestGenerator(
        `${process.env.REACT_APP_HOST}/${resource}/${id}`,
        params,
      );
      try {
        const response = await APIHelper(
          "GET",
          url.toString(),
          null,
        );
        return resolve(response);
      } catch (error) {
        return reject(error);
      }
    });
  }

  return {
    create,
    getList,
    getOne,
  };
};

export default GeneralAPICall;
