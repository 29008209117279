import { Calendar, TimeInput } from "@mantine/dates";
import { useContext } from "react";
import BookViewModel from "../../viewModel";
import dayjs from "dayjs";
import { Group, Stack, Text } from "@mantine/core";
import { IconClock } from "@tabler/icons";

const TimeslotForm = () => {
  const { bookForm, availableWeekNum, startHour, endHour } = useContext(
    BookViewModel.Context
  );

  return (
    <Group position="center" align="start" spacing={48}>
      <Stack align="center">
        <Text size="sm" color="gray">
          Date to visit
        </Text>
        <Calendar
          excludeDate={(date) =>
            date <= dayjs(Date.now()).endOf("day") ||
            !availableWeekNum.includes(date.getDay())
          }
          {...bookForm.getInputProps("date")}
        />
      </Stack>
      {bookForm.getInputProps("date").value && (
        <Stack align="center">
          <Text size="sm" color="gray">
            Time to visit
          </Text>

          <TimeInput
            size="md"
            required
            icon={<IconClock />}
            description={`Opening Hour from ${startHour.format("HH:mm")} to ${endHour.format("HH:mm")}`}
            {...bookForm.getInputProps("start")}
          />
        </Stack>
      )}
    </Group>
  );
};

export default TimeslotForm;
