import { Anchor, Paper, Stack, Text } from "@mantine/core";
import { Link } from "react-router-dom";

const IconCard = ({ icon, title, href }) => {
  return (
    <Anchor component={Link} to={href} style={{ textDecoration: "none" }}>
      <Paper
        shadow="xs"
        p="md"
        radius="md"
        withBorder
        sx={(theme) => ({
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: theme.colors.pink[0],
          },
          transition: "all 0.2s ease-out"
        })}
      >
        <Stack
          align="center"
          justify="center"
          p="sm"
          sx={(theme) => ({
            borderRadius: theme.radius.sm,
            minWidth: 200,
            minHeight: 200,
            cursor: "pointer",
            "&:hover": {
              color: theme.colors.pink[7],
            },
          })}
        >
          {icon}
          <Text>{title}</Text>
        </Stack>
      </Paper>
    </Anchor>
  );
};

export default IconCard;
