import { useContext } from "react";
import { Card, LoadingOverlay, Stack } from "@mantine/core";
import CustomStepper from "./containers/Stepper";
import BookForm from "./containers/Form/BookForm";
import BookViewModel from "./viewModel";

const Book = () => {
  const { bookStep, setBookStep, isLoading } = useContext(
    BookViewModel.Context
  );

  return (
    <>
      <LoadingOverlay visible={isLoading} loaderProps={{ color: "pink" }} />

      <Card shadow="sm" p="lg" radius="md">
        <Stack align="stretch">
          <CustomStepper active={bookStep} setActive={setBookStep} />
          <BookForm />
        </Stack>
      </Card>
    </>
  );
};

export default BookViewModel.withProvider(Book);
