import { Button, Group } from "@mantine/core";
import { useContext } from "react";
import BookViewModel from "../../viewModel";

const FormNavButtons = () => {
  const { bookStep, submitTimeslot, prevStep, nextStep, submitName } = useContext(
    BookViewModel.Context
  );

  return (
    <Group position="center" mt="md">
      {bookStep === 1 && (
        <>
          <Button color="pink" onClick={prevStep}>
            Back
          </Button>
          <Button color="pink" onClick={submitTimeslot}>
            Next
          </Button>
        </>
      )}
      {bookStep === 2 && (
        <>
          <Button color="pink" onClick={prevStep}>
            Back
          </Button>
          <Button color="pink" onClick={submitName}>
            Review
          </Button>
        </>
      )}
      {bookStep === 3 && (
        <>
          <Button color="pink" onClick={prevStep}>
            Back
          </Button>
          <Button color="pink" type="submit">
            Submit
          </Button>
        </>
      )}
    </Group>
  );
};

export default FormNavButtons;
