import {
  Anchor,
  Group,
  Navbar,
  Text,
  ThemeIcon,
  UnstyledButton,
} from "@mantine/core";
import React from "react";
import { LINKS } from "../../constants/navbar";
import { Link } from "react-router-dom";

const IconLink = ({ color = "pink", link }) => {
  return (
    <Anchor component={Link} to={link.href} style={{ textDecoration: "none" }}>
      <UnstyledButton
        sx={(theme) => ({
          display: "block",
          width: "100%",
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color: theme.black,
          "&:hover": {
            backgroundColor: theme.colors.gray[0],
          },
        })}
      >
        <Group>
          <ThemeIcon color={color} variant="light">
            {link.icon}
          </ThemeIcon>
          <Text>{link.title}</Text>
        </Group>
      </UnstyledButton>
    </Anchor>
  );
};

const CustomNavbar = ({ opened }) => {
  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      hidden={!opened}
      width={{ sm: 200, lg: 300 }}
    >
      <Navbar.Section>
        {LINKS.map((link) => (
          <IconLink link={link} key={link.href} />
        ))}
      </Navbar.Section>
    </Navbar>
  );
};

export default CustomNavbar;
