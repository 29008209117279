import { useForm } from "@mantine/form";
import BaseViewModel from "../../base/BaseViewModel";
import { useEffect, useState } from "react";
import { weekdayToInt } from "../../constants/book";
import dayjs from "dayjs";
import { decimalToTime, timeToDecimal } from "../../utility/helperFunction";
import GeneralAPICall from "../../base/GeneralAPICall";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import book from "./index";

function useBook() {
  const [isLoading, setIsLoading] = useState(false);
  const [bookStep, setBookStep] = useState(0);
  const doctorHook = GeneralAPICall("doctor");
  const bookingHook = GeneralAPICall("booking");
  const navigate = useNavigate();

  const nextStep = () =>
    setBookStep((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setBookStep((current) => (current > 0 ? current - 1 : current));

  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [showDoctorDetail, setShowDoctorDetail] = useState(false);
  const [availableWeekNum, setAvailableWeekNum] = useState([]);
  const [startHour, setStartHour] = useState(0);
  const [endHour, setEndHour] = useState(0);

  const fetchDoctors = async () => {
    setIsLoading(true);
    try {
      const res = await doctorHook.getList();
      setDoctors(res);
    } catch (e) {
      showNotification({
        color: "red",
        title: "Error",
        message: "Cannot fetch data from server",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDoctors();
  }, []);

  const bookForm = useForm({
    initialValues: {
      doctor: null,
      start: null,
      date: null,
      name: "",
    },
    validate: {
      doctor: (value) => (value === null ? "Missing doctor" : null),
      start: (value) => (value === null ? "Missing appointment hour" : null),
      date: (value) => (value === null ? "Missing appointment date" : null),
      name: (value) => (value.length <= 0 ? "Missing name" : null),
    },
  });

  const showDoctorDetailModal = async (id) => {
    try {
      setIsLoading(true);
      const doctor = doctors.find((d) => d?.id === id);
      setSelectedDoctor(doctor);
      setShowDoctorDetail(true);
    } catch (e) {
      showNotification({
        color: "red",
        title: "Error",
        message: "Cannot fetch data from server",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const bookDoctor = (id) => {
    bookForm.setFieldValue("doctor", id);
    setBookStep(1);
    const doctor = doctors.find((d) => d?.id === id);
    setSelectedDoctor(doctor);
    // prepare day of week available for selected doctor to disable dates in calendar
    const weekList = doctor?.opening_hours.map((openHour) => {
      if (!openHour?.isClosed) return weekdayToInt[openHour?.day];
    });
    setAvailableWeekNum(weekList);
  };

  const getStartEndHours = (doctor, dayOfWeek) => {
    const openingHours = doctor?.opening_hours.find(
      (openHour) => weekdayToInt[openHour?.day] === dayOfWeek
    );
    return {
      start: decimalToTime(openingHours?.start),
      end: decimalToTime(openingHours?.end),
    };
  };

  // show opening hours in UI base on date selected
  useEffect(() => {
    const dayOfWeek = dayjs(bookForm.getInputProps("date").value).day();
    const openingHours = getStartEndHours(selectedDoctor, dayOfWeek);
    setStartHour(openingHours.start);
    setEndHour(openingHours.end);
  }, [bookForm.getInputProps("date").value, selectedDoctor]);

  const submitTimeslot = () => {
    // if (bookForm.getInputProps("start").value <= new Date()) {
    //   bookForm.setErrors({ start: "You cannot book in the past" });
    //   return;
    // }
    const hour = dayjs(bookForm.getInputProps("start").value).hour();
    const min = dayjs(bookForm.getInputProps("start").value).minute();
    const selectedTime = timeToDecimal(hour, min);
    if (
      !selectedTime ||
      selectedTime < timeToDecimal(startHour.hour(), startHour.minute()) ||
      selectedTime > timeToDecimal(endHour.hour(), endHour.minute())
    ) {
      bookForm.setErrors({ start: "Time is outside opening hours" });
    } else {
      nextStep();
    }
  };

  const submitName = () => {
    if (!bookForm.getInputProps("name").value.trim()) {
      bookForm.setErrors({ name: "Missing name" });
    } else {
      nextStep();
    }
  };

  const submitBook = async (values) => {
    const date = dayjs(values.date).format("YYYY-MM-DD");
    const minute = dayjs(values.start).format("mm");
    const hour = dayjs(values.start).format("HH");
    const start = timeToDecimal(hour, minute);
    const formatted = {
      doctorId: values?.doctor,
      date,
      start,
      name: values?.name,
    };
    setIsLoading(true);
    try {
      await bookingHook.create(formatted);
      showNotification({
        color: "green",
        title: "Booking created successfully",
        message: "We got your booking details!",
      });
      navigate("/bookings");
    } catch (e) {
      showNotification({
        color: "red",
        title: "Error",
        message: "We cannot create your booking",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    bookForm,
    bookDoctor,
    bookStep,
    setBookStep,
    nextStep,
    prevStep,
    doctors,
    selectedDoctor,
    submitBook,
    availableWeekNum,
    startHour,
    endHour,
    submitName,
    submitTimeslot,
    isLoading,
    showDoctorDetail,
    setShowDoctorDetail,
    showDoctorDetailModal,
  };
}

const BookViewModel = BaseViewModel(useBook);

export default BookViewModel;
