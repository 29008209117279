import { decimalToTime } from "../../../utility/helperFunction";
import { Table } from "@mantine/core";
import { weekdayToInt } from "../../../constants/book";

const WorkHoursTable = ({ selectedDoctor }) => {
  const sortedHours = selectedDoctor?.opening_hours.sort((a, b) => {
    return weekdayToInt[a?.day] - weekdayToInt[b?.day];
  });

  return (
    <Table>
      <thead>
        <tr>
          <th>Day of Week</th>
          <th>Opening Hours</th>
        </tr>
      </thead>
      <tbody>
        {sortedHours.map((openHour) => {
          if (openHour?.isClosed) return <></>;
          return (
            <tr key={openHour?.day}>
              <td>{openHour?.day}</td>
              <td>
                {decimalToTime(openHour?.start).format("HH:mm")} -
                {decimalToTime(openHour?.end).format("HH:mm")}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default WorkHoursTable;
