import { Grid } from "@mantine/core";
import DoctorCard from "../../components/DoctorCard";
import { useContext } from "react";
import BookViewModel from "../../viewModel";

const DoctorForm = () => {
  const { bookDoctor, doctors, showDoctorDetailModal } = useContext(
    BookViewModel.Context
  );

  return (
    <Grid>
      {doctors.map((doctor) => (
        <Grid.Col md={6} lg={4} key={doctor?.id}>
          <DoctorCard
            onBook={() => bookDoctor(doctor?.id)}
            doctor={doctor}
            onShowDetail={() => showDoctorDetailModal(doctor?.id)}
          />
        </Grid.Col>
      ))}
    </Grid>
  );
};

export default DoctorForm;
