import { useContext } from "react";
import { Modal, Group, Text, useMantineTheme, Box } from "@mantine/core";
import BookViewModel from "../viewModel";
import { IconClock, IconMapPin } from "@tabler/icons";
import WorkHoursTable from "./WorkHoursTable";

const DoctorModal = () => {
  const { showDoctorDetail, setShowDoctorDetail, selectedDoctor } = useContext(
    BookViewModel.Context
  );
  const theme = useMantineTheme();

  return (
    <Modal
      opened={showDoctorDetail}
      onClose={() => setShowDoctorDetail(false)}
      title={`Profile of Dr. ${selectedDoctor?.name?.toUpperCase()}`}
    >
      <Text
        weight={500}
        style={{ marginBottom: theme.spacing.sm, textTransform: "uppercase" }}
      >{`Dr. ${selectedDoctor?.name} (${selectedDoctor?.id})`}</Text>

      {selectedDoctor?.description && (
        <Text
          size="sm"
          style={{ color: theme.colors.gray[7], lineHeight: 1.5 }}
        >
          {selectedDoctor?.description}
        </Text>
      )}

      <Box style={{ marginTop: theme.spacing.md }}>
        <Group style={{ marginBottom: theme.spacing.sm }}>
          <IconClock size={20} />
          <Text>Opening Hours</Text>
        </Group>
        <WorkHoursTable selectedDoctor={selectedDoctor} />
      </Box>
      <Box style={{ marginTop: theme.spacing.md }}>
        <Group style={{ marginBottom: theme.spacing.sm }}>
          <IconMapPin size={20} />
          <Text>Location</Text>
        </Group>
        <address>
          <Text size="sm">{selectedDoctor?.address?.line_1}</Text>
          <Text size="sm">{selectedDoctor?.address?.line_2}</Text>
          <Text size="sm">{selectedDoctor?.address?.district}</Text>
        </address>
      </Box>
    </Modal>
  );
};

export default DoctorModal;
