import {Box, Group, Text, useMantineTheme} from "@mantine/core";

const ReviewCard = ({icon, title, content}) => {

  const theme = useMantineTheme();

  return (
    <Box mx={theme.spacing.xl}>
      <Group style={{ marginBlock: theme.spacing.sm }} position="center" spacing={theme.spacing.xs}>
        {icon}
        <Text size="sm" color={theme.colors.gray[7]}>{title}</Text>
      </Group>
      <Text align="center">{content}</Text>
    </Box>
  )
}

export default ReviewCard;