import dayjs from "dayjs";

export const timeToDecimal = (hour, minute) => {
  const min = parseInt(minute, 10);
  const minFlaot = parseFloat((min / 60).toFixed(2));
  const hourFloat = parseFloat(hour);
  return hourFloat + minFlaot;
};

export const decimalToTime = (decimal) => {
  const hour = Math.floor(parseFloat(decimal));
  const minute = (decimal - hour).toFixed(2) * 60;
  return dayjs().startOf("day").add(hour, "hour").add(minute, "minute");
};
