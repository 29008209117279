import { Group, useMantineTheme } from "@mantine/core";
import { useContext } from "react";
import BookViewModel from "../../viewModel";
import dayjs from "dayjs";
import { IconClock, IconId, IconMapPin, IconUserSearch } from "@tabler/icons";
import ReviewCard from "../../components/ReviewCard";

const FormReview = () => {
  const theme = useMantineTheme();
  const { selectedDoctor, bookForm } = useContext(BookViewModel.Context);

  const { date, name, start } = bookForm.values;

  const formattedTimeslot = dayjs(date)
    .startOf("date")
    .add(dayjs(start).hour(), "hour")
    .add(dayjs(start).minute(), "minute")
    .format("YYYY-MM-DD HH:mm");

  const items = [
    {
      icon: <IconUserSearch />,
      title: "Doctor",
      content: selectedDoctor?.name?.toUpperCase(),
    },
    {
      icon: <IconMapPin />,
      title: "Location",
      content: selectedDoctor?.address?.district,
    },
    {
      icon: <IconClock />,
      title: "Timeslot (1 hour)",
      content: formattedTimeslot,
    },
    {
      icon: <IconId />,
      title: "Name",
      content: name,
    },
  ];

  return (
    <Group position="center" my={theme.spacing.xl}>
      {items.map((item) => (
        <ReviewCard
          key={item.title}
          icon={item.icon}
          title={item.title}
          content={item.content}
        />
      ))}
    </Group>
  );
};

export default FormReview;
