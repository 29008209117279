import {
  Badge,
  Button,
  Card,
  Group,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { IconClock, IconMapPin } from "@tabler/icons";
import { weekdayToInt } from "../../../constants/book";
import dayjs from "dayjs";
import { decimalToTime } from "../../../utility/helperFunction";

const DoctorCard = ({ onBook, doctor, onShowDetail }) => {
  const theme = useMantineTheme();

  const openHour = doctor?.opening_hours.find(
    (openHour) => weekdayToInt[openHour?.day] === dayjs().day()
  );

  const start = decimalToTime(openHour.start).format("HH:mm");
  const end = decimalToTime(openHour.end).format("HH:mm");
  const formattedOpeningHour = `${start} - ${end}`;

  return (
    <Card p="md" withBorder>
      <Group position="apart" style={{ marginBottom: theme.spacing.md }}>
        <Text
          weight={500}
          style={{ textTransform: "uppercase" }}
        >{`Dr. ${doctor?.name}`}</Text>
        <Badge color={doctor?.isClosed ? "gray" : "green"} variant="light">
          {doctor?.isClosed ? "Closed" : "Open"}
        </Badge>
      </Group>

      <Text size="sm" style={{ color: theme.colors.gray[7], lineHeight: 1.5 }}>
        {doctor?.description}
      </Text>

      <Group style={{ marginTop: theme.spacing.sm }}>
        <IconClock size={16} />
        <Text size="sm">{formattedOpeningHour}</Text>
      </Group>
      <Group style={{ marginTop: theme.spacing.sm }}>
        <IconMapPin size={16} />
        <Text size="sm">{doctor?.address?.district}</Text>
      </Group>

      <Group style={{ marginTop: theme.spacing.md }} position="apart">
        <Button variant="light" color="pink" onClick={onBook}>
          Book now
        </Button>
        <Button variant="subtle" color="pink" onClick={onShowDetail}>
          Detail
        </Button>
      </Group>
    </Card>
  );
};

export default DoctorCard;
